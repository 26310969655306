import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import React from "react";

import { styled } from "@material-ui/styles";
import {
  Editicon,
  Linkicon,
  backgroundicon,
  playblue,
  sendicon,
  repost,
  deleteicon,
  prevIC,
} from "../../../../dashboard/src/assets";
import SinglePlaylistController from "./SinglePlaylistDetailsController";
import CtrlChart from "../Common/CtrlChart.web";
import PlaylistVideoPlayer from "./PlaylistVideoPlayer.web";
import { DMRound, defaultProfile } from "blocks/dashboard/src/assets";
import InviteLink from "../Tracks/InviteLink.web";
import MessageLinkPopup from "components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web";
import { Avatar } from "antd";
import { Repost } from "blocks/dashboard/src/CustomComponent/SharePlaylistPopup/assets";
import UnlockContentCommon from "blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommon.web";
import { unlockRedirectionCheck } from "blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommonController.web";

const VideoPlaylistDetailswrapper = styled(Box)({
  width: "100%",
  display: "flex",
  gap: "10px",
  "@media(max-width: 1024px)": {
    width: "100%",
    flexDirection: "column",
  },
});
const VideoPlaylistDetailswrapper1 = styled(Box)({
  height: "810px",
  overflowY: "scroll",
  scrollbarWidth: "none",
  paddingLeft: "22px",
  "@media(max-width: 700px)": {
    height: "auto",
   
  },
});

const Borderboxweb = styled(Box)({
  "@media(max-width: 1024px)": {
    display: "none",
  },
});
const Statusbuttonox = styled(Box)({
  width: "100%",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column" as "column",
  justifyContent: "space-between",
  padding: "6px",
  gap: "20px",
  "@media(max-width: 600px)": {
    flexDirection: "row",
  },
});

const Rightsidewonerbox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  "@media(max-width: 600px)": {
    flexDirection: "column" as "column",
    gap: "10px",
  },
});

const Statusfunctionbox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media(max-width: 600px)": {
    flexDirection: "column" as "column",
  },
});

const VideoPlaylistRightideboxwrapper = styled(Box)({
  width: "50%",
  display: "flex",
  justifyContent: "center",
  marginTop: "-35px",
  paddingBottom: "20px",
  "@media(max-width: 1024px)": {
    width: "100%",
    marginTop: "5px",
  },
});
const VideoPlaylistfirstbox = styled(Box)({
  width: "50%",
  "@media(max-width: 1024px)": {
    width: "100%",
  },
});
interface LastSevenDaysGraph {
  Monday: number;
  Tuesday: number;
  Wednesday: number;
  Thursday: number;
  Friday: number;
  Saturday: number;
  Sunday: number;
}

interface PlaylistItemAttributes {
  art_work: string;
  artist_id: number;
  artist_image: string;
  artist_name: string;
  comments_count: number;
  last_seven_days_graph: LastSevenDaysGraph;
  last_seven_days_percentage: number;
  last_twenty_four_hours_percentage: number;
  play_count: number;
  repost_count: number;
  title: string;
  track_file: string;
  user_follow: boolean;
  user_type: string;
  video_file: string | null;
}

interface PlaylistItem {
  id: string;
  type: string;
  attributes: PlaylistItemAttributes;
}

export default class PlaylistDetailsvideo extends SinglePlaylistController {
  returnAvatarListData = (accessAvatarList: any) => {
    return (
      <>
        {accessAvatarList?.length <= 3 ? (
          <Avatar.Group maxCount={3} style={{ cursor: "pointer" }}>
            {accessAvatarList?.map((item: any, index: any) => (
              <Avatar
                key={index}
                src={item || defaultProfile}
                style={this.props.webStyle.avatartAccessImg}
                onClick={this.props.setAccessListView}
              />
            ))}
          </Avatar.Group>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "9px",
              cursor: "pointer",
            }}
            onClick={this.props.setAccessListView}
          >
            <Avatar.Group maxCount={3}>
              {accessAvatarList?.slice(0, 3).map((item: any, index: any) => (
                <Avatar
                  key={index}
                  src={item || defaultProfile}
                  style={this.props.webStyle.userimage}
                />
              ))}
            </Avatar.Group>
            <Typography className="details-access-number">
              {accessAvatarList?.length > 0
                ? `+${accessAvatarList?.length - 3}`
                : 0}
            </Typography>
          </Box>
        )}
      </>
    );
  };
  render() {
    const { selectedTrack, playlist_details_data, formatNumber } = this.props;
    const { attributes } = this.props.playlistDetailsData;
   
    return (
      //Merge Engine DefaultContainer
      <>
        <VideoPlaylistDetailswrapper>
        <Hidden only={["lg", "md", "xl", "sm"]}> 
        <Box style={{width:"100%",display:"flex",alignItems:"center",gap:"110px",marginLeft:'10px'}}>
        <img src={prevIC}   onClick={this.backtoPlaylist}  style={{height:"30px",width:'30px'}}/>
                      <Typography style={playlistwebStyle.trackname}>Playlist Details</Typography>
        </Box>
      
         </Hidden>
          <VideoPlaylistfirstbox>
            <Box style={playlistwebStyle.videosinglePlaylistleftsidefirstbox}>
              <Box>
                <Typography style={playlistwebStyle.trackname}>
                  <span style={playlistwebStyle.videobluetext}>
                    {attributes?.playlist_items?.data?.length}
                  </span>{" "}
                  Videos
                </Typography>
              </Box>
            </Box>
            <VideoPlaylistDetailswrapper1
              style={{
               
              }}
            >
              {attributes?.playlist_items?.data.length > 0 ? (
                attributes.playlist_items.data.map(
                  (item: PlaylistItem, index: number) => {
                    return (
                      <Box
                        key={index}
                        style={playlistwebStyle.videoleftSideSingletrackbox}
                        onClick={() => {
                          this.setState({ localSeletedItem: true });
                          unlockRedirectionCheck(selectedTrack,[item],item);
                        }}
                        className={
                          item.id == playlist_details_data.id
                            ? "playlist_track_border"
                            : "aaa"
                        }
                        data-test-id="playlistVideo-track"
                      >
                        <Box style={playlistwebStyle.videosingleArtinfo}>
                          <Box style={{position: 'relative', width: '125.25px',height: '75px'}}>
                          <img
                            src={item?.attributes?.art_work || defaultProfile}
                            style={playlistwebStyle.videocard}
                          />
                            <UnlockContentCommon trackData={item} width='100%' height='100%' padding='5px' />
                          </Box>
                          <Box style={playlistwebStyle.videoleftsideownerstats}>
                            <span style={playlistwebStyle.trackname}>
                              {item?.attributes?.title}
                            </span>

                            <Box style={playlistwebStyle.ownerStatsbox}>
                              <span style={playlistwebStyle.videosplittext}>
                                {item?.attributes?.artist_name}
                              </span>

                              <img
                                src={playblue}
                                style={playlistwebStyle.playimage}
                              />
                              <Typography style={playlistwebStyle.totalPlays}>
                                {formatNumber(item?.attributes?.play_count)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <img
                            src={backgroundicon}
                            style={playlistwebStyle.backgroundimage}
                            onClick={() => {
                              unlockRedirectionCheck(this.redirectVideo,[item], item);
                            }}
                            data-test-id="playlistvideo-redirect"
                          />
                        </Box>
                      </Box>
                    );
                  }
                )
              ) : (
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#fff",
                    padding: "5",
                    justifyContent: "center",
                  }}
                >
                  Data Not Found.
                </Typography>
              )}
            </VideoPlaylistDetailswrapper1>
          </VideoPlaylistfirstbox>
          <Borderboxweb>
            <hr
              style={{
                height: "102%",
                width: "1px",
                border: "1px solid #212121",
              }}
            />
          </Borderboxweb>
          <VideoPlaylistRightideboxwrapper>
            <Box style={playlistwebStyle.videolaylistrightsidebox}>
              <Box style={playlistwebStyle.videoplaylistrightsidevideobox}>
                <Grid
                  container
                  item
                  xs={12}
                  className="videos_stream_container videos_scroll"
                  style={{ flexGrow: 1 }}
                >
                  <PlaylistVideoPlayer data={playlist_details_data} currentTrack={this.props.playlistDetailsData}
                  selectedTrack={(item:any)=>selectedTrack(item)}
                  />
                  {/* </div> */}
                </Grid>
              </Box>

              <Box style={playlistwebStyle.rightsideownerstatswrapper}>
                <Rightsidewonerbox>
                  <Box style={playlistwebStyle.rightsideownerelementbox}>
                    <Box>
                      <img
                        src={playlist_details_data?.attributes?.art_work}
                        style={playlistwebStyle.videoplayercover}
                      />
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        style={playlistwebStyle.videototalreleasetext}
                      >
                        {playlist_details_data?.attributes?.artist_name}
                      </Typography>
                      <Typography style={playlistwebStyle.videoplaytext}>
                        {playlist_details_data?.attributes?.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={playlistwebStyle.videofollowrepostbox}>
                    <Box style={playlistwebStyle.followrepostbox}>
                      <Typography
                        style={playlistwebStyle.videototalreleasetext}
                      >
                        Plays
                      </Typography>
                      <Typography style={playlistwebStyle.videoplaytext}>
                        {playlist_details_data?.attributes?.play_count}
                      </Typography>
                    </Box>
                    <Box style={playlistwebStyle.followrepostbox}>
                      <Typography
                        style={playlistwebStyle.videototalreleasetext}
                      >
                        Follows
                      </Typography>
                      <Typography style={playlistwebStyle.videoplaytext}>
                        {attributes?.followers_count}
                      </Typography>
                    </Box>
                    <Box style={playlistwebStyle.followrepostbox}>
                      <Typography
                        style={playlistwebStyle.videototalreleasetext}
                      >
                        Reposts
                      </Typography>
                      <Typography style={playlistwebStyle.videoplaytext}>
                        {playlist_details_data?.attributes?.repost_count}
                      </Typography>
                    </Box>
                  </Box>
                </Rightsidewonerbox>
              </Box>
              <Statusfunctionbox>
                <Statusbuttonox>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                   
                  {this.props.accessAvatarList?.length > 0 ? (
                this.returnAvatarListData(this.props.accessAvatarList)
              ) : (
                <Typography style={this.props.webStyle.statustext}>
                  No Access
                </Typography>
              )}
                  </Box>
                  <Box>
                    <Button
                      style={{
                        border:
                          attributes?.status == "public_playlist"
                            ? "1px solid #3959F9"
                            : "1px solid #FF0501",
                        background: "#000",
                        color:
                          attributes?.status == "public_playlist"
                            ? "#3959F9"
                            : "#FF0501",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "normal",
                        letterSpacing: "-0.32px",
                        fontFamily: "Inter",
                        textTransform: "capitalize" as "capitalize",
                        borderRadius: "30px",
                      }}
                    >
                      {attributes?.status == "public_playlist"
                        ? "Public"
                        : "Private"}
                    </Button>
                  </Box>
                </Statusbuttonox>
                <Box style={{ display: "flex", gap: "20px" }}>
                  <Box style={playlistwebStyle.videoiconsboxwrapper}>
                    <img
                      src={Editicon}
                      className="edit_image"
                      onClick={() => {
                        this.props.showEditPlaylist();
                      }}
                      data-test-id="showEditPlaylist"
                    />
                    <Typography style={playlistwebStyle.videoedittext}>
                      Edit
                    </Typography>
                  </Box>
                  <Box
                    style={playlistwebStyle.videoiconsboxwrapper}
                    onClick={this.handleOpenInvitation}
                  >
                    <img src={Linkicon} className="edit_image" />
                    <Typography style={playlistwebStyle.videoedittext}>
                      Invite
                    </Typography>
                  </Box>
                  <Box
                    style={playlistwebStyle.videoiconsboxwrapper}
                    data-test-id="invite"
                    onClick={() => {
                      this.props.linkopenPopup();
                    }}
                  >
                    <img src={DMRound} className="edit_image" />
                    <Typography style={playlistwebStyle.videoedittext}>
                      DM
                    </Typography>
                  </Box>
                  <Box style={playlistwebStyle.videoiconsboxwrapper}>
                    <img
                      src={Repost}
                      className="edit_image"
                      onClick={() => {
                        this.props.handleRepost();
                      }}
                      data-test-id="edit_img"
                    />
                    <Typography style={playlistwebStyle.videoedittext}>
                      Repost
                    </Typography>
                  </Box>
                  <Box
                    style={playlistwebStyle.videoiconsboxwrapper}
                    onClick={() => {
                      this.props.showDeletePlaylist();
                    }}
                    data-test-id="deleted_icon"
                  >
                    <img src={deleteicon} className="edit_image" />
                    <Typography style={playlistwebStyle.videoedittext}>
                      Delete
                    </Typography>
                  </Box>
                </Box>
              </Statusfunctionbox>

              <Box style={{ width: "100%", height: "300px" }}>
                <CtrlChart
                  navigation={undefined}
                  id={""}
                  isgradient={false}
                  labellength={7}
                  chartData={Object.values(
                    attributes?.last_seven_days_graph || []
                  )}
                />
                <Box style={{ width: "100%" }}>
                  <Box style={playlistwebStyle.weekdaysbox}>
                    {["S", "M", "T", "W", "T", "F", "S"].map((item, index) => (
                      <Box
                        style={playlistwebStyle.videosingledaybox}
                        key={index}
                      >
                        <Typography style={playlistwebStyle.videoweeknametext}>
                          {item}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </VideoPlaylistRightideboxwrapper>
        </VideoPlaylistDetailswrapper>
        <InviteLink
          title={"Send Invite Link"}
          subTitle={""}
          isOpen={this.state.openInvitationModalPlaylist}
          handleDMClose={() => {
            this.setState({ openInvitationModalPlaylist: false });
          }}
          handleDelete={this.linkopenPopup}
        />
        <MessageLinkPopup
          id="Profile"
          copiedLinked={this.state.CopiedForLinkPlaylist}
          showMsgModal={this.state.openMessageSendPopupPlaylist}
          closeMsgModal={this.handleOpenMessageSendPopup}
          closeModalDialog={this.handleOpenMessageSendPopup}
          currentUserId={1}
          onInvitedUser={() => {}}
          track_whole_detailsId={this.props.playlistDetailsData.id}

        />
      </>

      //Merge Engine End DefaultContainer
    );
  }
}

const playlistwebStyle = {
  statusfunctionbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  videoplayercover: {
    height: "60px",
    width: "60px",
    borderRadius: "60px",
  },
  rightsideownerelementbox: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  followrepostbox: {
    display: "flex",
    gap: "10px",
    flexDirection: "column" as "column",
  },
  rightsidewonerbox: {
    display: "flex",
    justifyContent: "space-between",
  },
  rightsideownerstatswrapper: {
    display: "flex",
    flexDirection: "column" as "column",
  },
  backgroundimage: {
    width: "35px",
    height: "35px",
    mixBlendMode:"lighten" as "lighten"
  },
  playimage: {
    width: "12px",
    height: "12px",
  },
  totalPlays: {
    color: "#3959F9",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "20px",
  },
  ownerStatsbox: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
  videoiconsboxwrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
  },
  weekdaysbox: {
    display: "flex",
    background: "#141414",
    borderRadius: "5px",
  },
  videostatusbutton: {
    border: "1px solid #FF0501",
    background: "#000",
    color: "#FF0501",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.32px",
    fontFamily: "Inter",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "30px",
  },
  leftsideimage: {
    border: "1px solid #141414",
    borderRadius: "10px",
    width: "125.25px",
    minWidth: "125.25px",
    height: "75px",
  },
  videoedittext: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
  },

  videoplaytext: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
  },
  videototalreleasetext: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.32px",
    color: "rgba(255, 255, 255, 0.50)",
  },
  videoweeknametext: {
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 700,
    color: "#FFF",
    // line-height: 16px
    marginLeft: "10px",
  },
  videosingledaybox: {
    display: "flex",
    justifyContent: "center",
    width: "17%",
  },
  videostatusbuttonbox: {
    width: "100%",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
    padding: "6px",
    gap: "20px",
  },
  videouserimage: {
    marginLeft: "-10px",
    width: "35px",
    height: "35px",
  },
  audiocard: {
    borderRadius: "10px",
    width: "75px",
    minWidth: "75px",
    height: "75px",
  },
  videocard: {
    borderRadius: "10px",
    width: "125.25px",
    minWidth: "125.25px",
    height: "75px",
    border: "1px solid #141414",
    position: "absolute" as "absolute",
  },

  singlevideoartisttext: {
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    position: "relative" as "relative",
    left: "20px",
    top: "-68px",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
  },
  videoArtisttext: {
    fontWeight: 700,
    fontSize: "14px",
    // position: "relative" as "relative",
    left: "4%",
    top: "-22%",
    fontFamily: "Karla",
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#8f92a1",
  },

  videosingleArtinfo: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },

  videoleftSideSingletrackbox: {
    marginTop: "6px",
    height: "75px",
    padding: "0px 14px 0px 0px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
  },

  trackname: {
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
    fontFamily: "Inter",
  },
  videoleftsideownerstats: {
    gap: "10px",
    display: "flex",
    flexDirection: "column" as "column",
  },
  videoeditimage: {
    borderRadius: "120px",
    border: "2px solid #141414",
    width: "60px",
    height: "60px",
  },

  videobluetext: {
    fontSize: "22px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    color: "#3959f9",
    fontWeight: 700,
  },

  videosplittext: {
    fontSize: "16px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    color: "#8f92a1",
    fontFamily: "Inter",
    whiteSpace: "nowrap" as "nowrap",
  },

  videosinglePlaylistleftsidefirstbox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 21px 0px 20px",
  },

  videolaylistrightsidebox: {
    width: "93%",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "30px",
  },
  videoplaylistrightsidevideobox: {
    width: "100%",
    position: "relative" as "relative",
  },
  videolaylistrightsidevideoimage: {
    width: "100%",
    height: "349px",
  },
  videofollowrepostbox: {
    display: "flex",
    justifyContent: "space-between",
    gap: "30px",
  },
};
// Customizable Area End
