// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col, Input, Form, Button, Upload } from "antd";
import './create-playlist.css'
import { EditIcon, PlusMath } from './assets.web';
import CreateAudioPlaylistPopupController from './CreateAudioPlaylistController.web';
import { nxzloader } from '../../assets';

export class CreateAudioPlaylistPopup extends CreateAudioPlaylistPopupController {

  addToPlaylistDiv = () =>{
    return(
      <div  style={{backgroundColor: !this.state.createPlaylist ? 'blue' : 'transparent', color: !this.state.createPlaylist ? 'white' : 'gray', cursor: 'pointer',borderRadius: '10px',fontSize: '16px', fontWeight: 'bold' , border: 'solid 2px #141414', padding: '10px 20px'}} >Add To Playlist</div>
    )
  }

  createPlaylistDiv = () =>{
    return (
      <div style={{backgroundColor: this.state.createPlaylist ? 'blue' : 'transparent' ,color: this.state.createPlaylist ? 'white' : 'gray', cursor: 'pointer', borderRadius: '10px',fontSize: '16px', fontWeight: 'bold', border: 'solid 2px #141414', padding: '10px 20px'}}>Create Playlist</div>
    )
  }

  playlistListingNameDiv = (playlist: any) =>{
    return(
      <Grid className={`${this.props.playlistType == 'audio' ? 'audio-image-width' : 'video-image-width'}`} style={{ height: '48px', borderRadius: '10px' }}><img style={{ width: '100%', height: '100%', borderRadius: '10px' }} src= {playlist.attributes.cover_image}></img> </Grid>
    )
  }

  buttonRenders = () =>{
    return(
    <div className='public-private-buttons'>
      <button type='button' data-test-id="privatebtn" className = "button1_private" style={{backgroundColor: this.state.privateActive ? 'blue' : 'transparent'}} value='private_playlist'  onClick={(e: any) => {
       this.setPrivate(e)}}>Private</button>
      <button type='button' className= "button2_public" style={{backgroundColor: this.state.publicActive ? 'blue' : 'transparent'}} value='public_playlist'  onClick={(e: any) => {
      this.setPublic(e)}}>Public</button>
   </div>  
    )
  }

  getPlaylistInfo(playlist:any, playlistType:any) {
    const itemCount = playlist.attributes.playlist_items.data.length;
    const itemType = playlistType === 'audio' ? 'Tracks' : 'Videos';
  
    return (
      <Grid style={{ color: 'gray', fontSize: '14px', fontWeight: 'bold', fontFamily: 'karla' }}>
        {itemCount} {itemType}
      </Grid>
    );
  }

  render() {    
    return (
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-create-audio"}}
     >
      {this.state.loading ? (
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : (
         <Row justify="center" >
                <Col >
                  <Grid container  style={{justifyContent: 'center', padding: '10px', flexDirection: 'column', alignItems: 'center'}}>
                  <Grid item style={{width: '100%', marginTop: '15px'}} data-test-id="send-sms" >
                    <Grid container style={{justifyContent: 'center'}} spacing={2}>
                      <Grid item xs={6} onClick={this.createAddToPlaylist}  className='height_width_menu'>
                        {this.addToPlaylistDiv()}
                      </Grid>
                      <Grid item xs={6} onClick={this.createPlaylist}  className='height_width_menu'>
                        {this.createPlaylistDiv()}
                      </Grid>
                    </Grid>

                  </Grid>
           {/* Charts Add to playlist popup  final */}
                  {
                    !this.state.createPlaylist &&
                    <Grid item style={{width: '100%', marginTop: '10px'}}>
                     <Grid>
                       {
                        this.state.userPlaylists.length > 0 ? 
                        this.state.userPlaylists.map((playlist: any, index: any) => {
                           return <Grid container style={{ justifyContent: 'space-between' ,marginTop: '16px'}} test-id="playlist_add_in_create_Audio_playlist_test_id" onClick={() => this.playlistAdd(playlist)}> 
                                    <Grid item style={{ display: 'flex'}}>
                                       {this.playlistListingNameDiv(playlist)}
                                       <Grid style={{ marginLeft: '10px' }}>
                                          <Grid style={{color: 'white', fontSize: '14px', fontWeight: 'bold', fontFamily: 'inter',overflow: 'hidden', textOverflow: 'ellipsis'}} className='playlist_name_width'>{playlist.attributes.name} </Grid>
                                           {this.getPlaylistInfo(playlist,this.props.playlistType)}
                                       </Grid>
                                    </Grid>

                                     <Grid item onClick={() => this.addSongorPlaylistToPlaylist(playlist)}>
                                       {   
                                       this.state.userPlaylists.length > 0 &&
                                            this.setPlaylist(playlist)
                                     } 
                                      </Grid>
                                  </Grid>
                        })
                        :
                        (
                          <div style={{color: 'white', textAlign: 'center', marginTop: '100px', fontSize: '24px', fontWeight: 'bold'}}>No Playlists Available</div>
                        )
                       }

                     </Grid>
                    </Grid>
                  }
                  
                  {
                    this.state.createPlaylist &&
                   <Grid item style={{width: '100%'}}>
                  <Form
                    name="basic"
                    ref={this.formRef}
                    className={`${this.props.playlistType == 'audio' ? 'create_audio_form': 'create_video_form'}`}
                    layout="vertical"
                    onFinish={this.onFinish}
                    autoComplete="off"
                    initialValues={{
                      Playlist_cover: this.state.values.Playlist_cover,
                      Playlist_name: this.state.values.playlist_name,
                      Playlist_status: this.state.values.playlist_status          
                    }}
                  >
                    <Grid container className='margin-container-main flex-direction-mobile' style={{width: '100%', alignItems: 'center', justifyContent: 'center'}} spacing={4}>
                      <Grid item style={{width: '50%'}}>
                         <Grid style={{display: 'flex',flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <Grid className = "outlined-upload">
                               UPLOAD
                            </Grid>
                            <Grid style={{fontSize: '32px', fontWeight: 900, color: 'white' }}>
                                COVER
                            </Grid>
                         </Grid>
                      </Grid>
                      
                      <Grid item style={{width: '50%'}}>
                      <Form.Item
                        name="Playlist_cover"
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px'}}
                        rules={[
                          {
                            required: true,
                            message: "Please Upload Cover Image!",
                          },
                        ]}
                        >
                        <Upload
                        name="Playlist_cover"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={this.beforeUpload}
                        customRequest={(options: any) => {
                            this.handleChange(options)

                        }}
                        >
                        {
                        <div style={{width: '100%',height: '100%', position: 'relative',borderRadius: '8px', backgroundColor: '#020202'}}>
                            {
                                this.state.values.Playlist_cover &&
                                <img src={this.state.values.Playlist_cover} alt="avatar" style={{ width: '100%', height: '100%' ,borderRadius: '8px', objectFit: 'cover'}} />
                            }
                            <div className={`${this.props.playlistType == 'audio' ? 'audio-edit-icon': 'video-edit-icon'}`}>
                            <img src={EditIcon} style={{width: '100%', height: '100%'}}/>
                            </div>
                            {
                              !this.state.values.Playlist_cover &&
                              <div style={{position: 'absolute',top: '40%',left: '40%' , width: '24px'}}>
                                <img src={PlusMath} style={{width: '100%', height: '100%'}}/>
                              </div>
                            }
                            
                        </div> 
                        
                        }
                        </Upload>
                        </Form.Item>
                        </Grid>
                    </Grid>


                   <Grid style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <Form.Item
                      label="Name New Playlist"
                      name="Playlist_name"
                      style={{width: '80%'}}
                      rules={[
                        {
                          required: true,
                          message: "Please Input Playlist Name!",
                        },
                      ]}
                    >
                      
                      <Input style={{fontSize: '22px',fontWeight: 'bold'}} />
                    </Form.Item>
                    </Grid>

                    <Form.Item
                      name="Playlist_status"
                      style={{width: '100%', display: 'flex', justifyContent: 'center'}}
                    >
                      {this.buttonRenders()}       
                   </Form.Item>

                    <Form.Item style={{display: 'flex', justifyContent: 'center'}}>
                      <Button
                        className="red_custom_btn_create mt-1"
                        htmlType="submit"
                        data-test-id="send-button"
                      >
                        Create Playlist
                      </Button>
                    </Form.Item>
                  </Form>
                  </Grid>
                  }
              </Grid>
            </Col>
         </Row>
      )
                }
     </Dialog>
    )
  }
}

export default CreateAudioPlaylistPopup
// Customizable Area End