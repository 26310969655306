// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { getStorageData } from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
import { openNotification } from "components/src/Notification.web";
export const configJSON = require("../config");


export interface Props {
    props?: any;
    //id?:string
}
interface Types {
    name: string
}
interface Genretype {
    name: string
}

interface ProfileTrackResponse{
    data:[
        ProfileTrack
    ],
    message:"",
    error:any
}
export interface ProfileTrack{
        "id": string,
        "type": string,
        "attributes": {
          "track_file": string,
          "video_file": string,
          "time_duration": string | null,
          "art_work": string,
          "artist_image": string,
          "account_id": number,
          "title": string,
          "description": string,
          "release_date": string,
          "release_time": string,
          "is_published": boolean,
          "upload_type_id": number,
          "upload_type": {
            "id": number,
            "name": string,
            "created_at": string,
            "updated_at": string
          },
          "audio_tag": string,
          "primary_genre": null,
          "sub_genre": null,
          "bpm": null,
          "key": null,
          "artist_name": string,
          "song_index": number,
          "repost_count": number,
          "play_count": number,
          "view_count": number,
          "comments_count": number,
          "comments": [],
          "is_commented": boolean,
          "is_reposted": boolean,
          "is_following": boolean,
          "is_download": boolean,
          "created_at": string,
          "updated_at": string,
          "status": string,
          "genre": [],
          "level": null,
          "linked_track_id": number,
          "upload_stage": null,
          "is_drafted": boolean,
          "acr_id": null,
          "photo_url": null,
          "followed_by_current_user": boolean,
          "track_repost_count": null,
          "track_upload_type": string,
          "linked_track": {
            "id": number,
            "title": string,
            "release_date": string,
            "release_time": string,
            "description": string,
            "is_published": boolean,
            "upload_type_id": number,
            "audio_tag_id": null,
            "primary_genre_id": null,
            "sub_genre_id": null,
            "bpm_id": null,
            "key_id": null,
            "created_at": string,
            "updated_at": string,
            "duration": string,
            "comments_count": number,
            "original": boolean,
            "artist_name": string,
            "play_count": number,
            "repost_count": number,
            "keyword": null,
            "last_twenty_four_hours_play_count": number,
            "last_seven_days_play_count": number,
            "last_seven_days_percentage": number,
            "last_twenty_four_hours_percentage": number,
            "stage": number,
            "status": string,
            "view_count": number,
            "send_count": number,
            "copy_link_count": number,
            "last_two_weeks_play_count": number,
            "last_two_weeks_percentage": number,
            "acr_id": null,
          }
      }
}
interface AdCampaignResponse{
        "data": {
            "id": string,
            "type": string,
            "attributes": {
                "id": number,
                "account_id": number,
                "track_id": number,
                "track_file": string,
                "video_file": null,
                "art_work": string,
                "status": "pending",
                "push_content_type": null,
                "placement_location": null,
                "content_type": null,
                "creative_type": [],
                "genres": [],
                "account_type": null,
                "cost_per_play": number,
                "cost_per_download": number,
                "cost_per_follow": number,
                "cost_per_impression": number,
                "cost_per_click": number,
                "cost_per_share": number,
                "cost_per_view": number,
                "total_budget": number,
                "campaign_duration": string,
                "ad_spend": number,
                "total_ad_spend": number,
                "total_actions_counts": {
                    "impression": number,
                    "click": number,
                    "share": number,
                    "play": number,
                    "view": number,
                    "download": number,
                    "follow": number
                },
                "pushed_at": null,
                "resumed_at": null,
                "stopped_at": null,
                "completed_at": null,
                "created_at": string,
                "updated_at": string
            }
        }
        "message":string
    }

    interface S {
    pushType: string,
    contentType: string,
    AccountType: string,
    selectedCreativeType: number[],
    selectedGenreType: number[],
    selectedCreativeTypeName: string[],
    selectedGenreTypeName: string[],
    activeStepmobile: string,
    isMobile: boolean;
    Addtype: string;
    selectTrack: string;
    trackstype: string;
    totalbudget: number | number[]
    cpp: number | number[]
    cpi: number | number[]
    cpc: number | number[]
    cpv: number | number[]
    cpd: number | number[]
    cpf: number | number[]
    cps: number | number[]
    budgetDuration: number | number[],
    allcreativetypes: Types[]
    allgenrestypes: Genretype[],
    PaymentMethod: string,
    playerAdcontentType: string,
    isLoading:boolean,
    token:string;
    profileTrackData:ProfileTrack[],
    searchText:string
}

interface SS {
    id: any;
}

export default class DisplayAdController extends BlockComponent<Props, S, SS> {
    getProfileTracksCallId:string="";
    addTrackInCampaignCallId:string="";
    putUpdateCampaignDataCallId:string=""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
            pushType: "playerAd",
            contentType: "Profiles",
            AccountType: "AllTypes",
            selectedCreativeType: [],
            selectedGenreType: [],
            activeStepmobile: "1",
            isMobile: window.innerWidth < 600,
            Addtype: "Beat",
            selectTrack: "",
            trackstype: "Beat",
            playerAdcontentType: 'All',
            totalbudget: 5000,
            cpp: 0.02,
            cpi: 0.02,
            cpc: 0.02,
            cpv: 0.02,
            cpd: 0.02,
            cpf: 0.02,
            cps: 0.02,
            budgetDuration: 5,
            PaymentMethod: "wallet",
            allcreativetypes: [{ name: "Artist" }, { name: "Creative" }, { name: "Digital Lable" },
            { name: "DJ" }, { name: "Engineer" }, { name: "Influencer" }, { name: "Musician" },
            { name: "Public Figure" }, { name: "Producer" }, { name: "Songwriter" }],
            allgenrestypes: [{ name: "Afrobeat" }, { name: "Alternative" }, { name: "CHH" }, { name: "Country" }, { name: "EDM" },
            { name: "Latin" }, { name: "Pop" }, { name: "Rap" }, { name: "Rnb" }, { name: "Soul" }, { name: "Trap" }
            ],
            isLoading:false,
            token:"",
            profileTrackData:[],
            searchText:"",
            selectedCreativeTypeName: [],
            selectedGenreTypeName: [],
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        super.componentDidMount();
        window.addEventListener('resize', this.handleResize);
        let token = await getStorageData("token");
        this.setState({ token: token });
        this._getProfileTracks('Beat',"")
    }
    componentDidUpdate(prevProps: {}, prevState: S) {
        if (prevState.isMobile !== this.state.isMobile) {
            this.handleResize();
        }
    }


    handleResize = () => {
        this.setState({ isMobile: window.innerWidth < 600 });
    };

    selecteTracksType = (type: string) => {
        this.setState({ trackstype: type })
        this._getProfileTracks(type,"")
    }

    selectAddType = (type: string) => {
        this.setState({ Addtype: type,trackstype:"Beat" })
        this._getProfileTracks(type,"")

    }

    _getProfileTracks=(upload_type:string,search: string) =>{
        this.setState({ isLoading: true,searchText:search })
       let searchParam=`bx_block_audiomusic/current_user_tracks?upload_type=${upload_type}`
       if(search !== ""){
            searchParam=`bx_block_audiomusic/current_user_tracks?upload_type=${upload_type}&search=${search}`
       }
        const header = {
          "Content-Type": "application/json",
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProfileTracksCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          searchParam
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }


      submitAdData=() =>{
        const formData: any = new FormData();
        formData.append('track_id', this.state.selectTrack);
        formData.append('content_type', this.state.playerAdcontentType);
        formData.append('creative_type', this.state.selectedCreativeTypeName);
        formData.append('genres', this.state.selectedGenreTypeName);
        const header = {
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addTrackInCampaignCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_promotecontent/ad_campaigns`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }

      _onUpdateAdCampaign=(campaign_id:string) =>{
        const userType = localStorage.getItem("user_type")
        const bodyParam={
            "push_ad": true, // set this as true ff finally pushing the ad
            "ad_campaign" : {
                "push_content_type": this.state.pushType == 'playerAd'?"player_ad":"display_ad", // "player_ad" or "display_ad"
                "content_type": this.state.playerAdcontentType, // player_ad and only for tracks and songs, Options - "Beat", "Verse", "Hook", "Song", "Video", "All"
                "creative_type": this.state.selectedCreativeTypeName, // player_ad and display_ad
                "genres": this.state.selectedGenreTypeName, // player_ad and display_ad
                "account_type": userType, // display_ad
                "cost_per_download": this.state.cpd, // player_ad if track upload type is (Beat, Hook, Verse)
                "cost_per_follow": this.state.cpf, // player_ad
                "cost_per_play": this.state.cpp, // player_ad or display_ad if selected content is Track Or Song
                "total_budget": this.state.totalbudget,
                "campaign_duration": this.state.budgetDuration 
            }
        }
        const header = {
          "Content-Type": "application/json",
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.putUpdateCampaignDataCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_promotecontent/ad_campaigns/${campaign_id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(bodyParam)
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }

    selectPushType = (type: string) => {
        this.setState({ pushType: type })
    }
    selectContentType = (type: string) => {
        this.setState({ contentType: type })
    }
    selectAccountType = (type: string) => {
        this.setState({ AccountType: type })
    }

    selectCreativeType = (indexes: number, type: 'creative' | 'genre') => {
        const stateKey = type === "creative" ? 'selectedCreativeType' : 'selectedGenreType'
        const nameKey = type === 'creative' ? 'selectedCreativeTypeName' : 'selectedGenreTypeName';
        const allTypes = type === 'creative' ? this.state.allcreativetypes : this.state.allgenrestypes;
        this.setState((prevState) => {
            let isSelected = prevState[stateKey].includes(indexes)
            const newSelectedCreativeTypes = isSelected
                ? prevState[stateKey].filter((i: number) => i !== indexes) // Remove if already selected
                : [...prevState[stateKey], indexes]; // Add if not selected
            const newSelectedTypeNames = isSelected
                ? prevState[nameKey].filter((name) => name !== allTypes[indexes].name)
                : [...prevState[nameKey], allTypes[indexes].name];
                return { [stateKey]: newSelectedCreativeTypes,
                    [nameKey]: newSelectedTypeNames
                }as any;
        })
    }

    setActiveStepmobile = (step: string) => {
        this.setState({ activeStepmobile: step })
    }
    onbackMobile = (step: string) => {
        this.setState({ activeStepmobile: step })
    }

    selectAnytrack = (index: string) => {
        if (index == this.state.selectTrack) {
            this.setState({ selectTrack: "" })
        } else {
            this.setState({ selectTrack: index })
        }

    }

    handleBudget = (event: any, newValue: number | number[]) => {
        this.setState({ totalbudget: newValue })
    };
    handleCpp = (event: any, newValue: number | number[]) => {
        this.setState({ cpp: newValue })
    };
    handleCpi = (event: any, newValue: number | number[]) => {
        this.setState({ cpi: newValue })
    };
    handleCpc = (event: any, newValue: number | number[]) => {
        this.setState({ cpc: newValue })
    };
    handleBudgetDuration = (event: any, newValue: number | number[]) => {
        this.setState({ budgetDuration: newValue })
    };
    selectPaymentMethod = (method: string) => {
        this.setState({ PaymentMethod: method })
    }
    handleCpv = (event: any, newValue: number | number[]) => {
        this.setState({ cpv: newValue })
    };
    handleCpd = (event: any, newValue: number | number[]) => {
        this.setState({ cpd: newValue })
    };
    handleCpf = (event: any, newValue: number | number[]) => {
        this.setState({ cpf: newValue })
    };
    handleCps = (event: any, newValue: number | number[]) => {
        this.setState({ cps: newValue })
    };
    selectPlayerAdcontentType = (string: string) => {
        this.setState({ playerAdcontentType: string })
        if(string == "Videos"){
            this.setState({Addtype:"Video"})
        }else{
            this.setState({Addtype:"Beat"})
        }
    }
    handleProfileTracksData=(responseJson:ProfileTrackResponse)=>{
        if (responseJson !== undefined && !responseJson.message && !responseJson.error) {
            if(responseJson.data.length>0){
                this.setState({profileTrackData:responseJson.data,isLoading:false})
            }else{
                this.setState({profileTrackData:[],isLoading:false})
        }
        }else{
            this.setState({profileTrackData:[],isLoading:false})
        }
    }

    handleAdTrackInCampaign=(responseJson:AdCampaignResponse)=>{
        if (responseJson !== undefined && !responseJson.message) {
            
        this._onUpdateAdCampaign(responseJson.data.id)
        }else{
           openNotification(`${responseJson.message} Please select a track to continue`,"Error")
        }

    }

    handleNavigation=()=>{
        const msg: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props.props);
        msg.addData(getName(MessageEnum.NavigationScreenNameMessage), {});
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AdConfirmation');
        this.send(msg);
        }
    
    
    handleAdCampaignData=(responseJson:any)=>{
        if (responseJson !== undefined && !responseJson.message) {
            openNotification("Push data has been activated","Success")
           this.handleNavigation()
        }else{
            openNotification(responseJson.message,"Error")
        }
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
          )
        if (apiRequestCallId === this.getProfileTracksCallId) {
            this.handleProfileTracksData(responseJson);
              
        }else if (apiRequestCallId=== this.addTrackInCampaignCallId){
            this.handleAdTrackInCampaign(responseJson)
        }else if(apiRequestCallId===this.putUpdateCampaignDataCallId){
            this.handleAdCampaignData(responseJson)
        }
        }
        // Customizable Area End
      }

}



// Customizable Area End