// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createBrowserHistory } from "history";
import { openNotification } from "../../../../components/src/Notification.web";

export const configJSON = require("./config");
const history = createBrowserHistory()

export interface Props {
  navigation: any;
  id: string;
  history: any;
}

interface S {
  songsList: any;
  hooksList: any;
  beatsList: any;
  versesList: any;
  playList: any;
  Top100List: any;
  isPlaylist: boolean;
  playlistData: any;
  loading: boolean;
  alertPopup: boolean;
  open: boolean;
  handleAudioVideo: number;
  dialogText: any;
  checkType: any;
  songIndex: number;
  active: any;
  selectedPlayList: any;
  handleplay: boolean;
  filterValue: any;
  hideDownloadInPlaylist:boolean
}

interface SS {
  id: any;
}

export default class ChartController extends BlockComponent<
  Props,
  S,
  SS
> {

  
  subscriptionPlan = localStorage.getItem("subscription_plans")

  getTop100ListsApiCallId: any
  getSongsListsApiCallId: any
  beatsListsApiCallId: any
  gethooksListsApiCallId: any
  getversesListsApiCallId: any
  getplayListsApiCallId: any
  handleFilterApiCallId: any
  getAdList:any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
      songsList: [],
      hooksList: [],
      beatsList: [],
      versesList: [],
      playList: [],
      Top100List: [],
      isPlaylist: false,
      playlistData: {},
      selectedPlayList: [],
      loading: false,
      alertPopup: false,
      open: false,
      handleAudioVideo: 0,
      dialogText: "",
      checkType: "",
      songIndex: 0,
      active: 0,
      handleplay: false,
      filterValue: "Filter",
      hideDownloadInPlaylist:true
    };


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  _getAdvertisementData = async (current_list: string) => {
    const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAdList = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_promotecontent/ad_campaigns/get_player_ads?upload_type=${current_list.toLowerCase()}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}

  getTop100ListsApiCallFunction = (responseJson: any) => {
  
    if (responseJson?.data) {
      this.setState({ Top100List: responseJson.data, loading: false })
    } else if (responseJson?.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }
  
  getSongsListsApiCallFunction = (responseJson: any) =>{
    if (responseJson?.data) {
      this.setState({ songsList: responseJson.data, loading: false })
    } else if (responseJson?.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
    this._getAdvertisementData("Song")
  }

  beatsListsApiCallFunction = (responseJson: any) =>{

    if (responseJson?.data) {
      this.setState({ beatsList: responseJson.data, 
      })
    } else if (responseJson?.errors) {
      this.setState({ loading: false });
    } else {
     return
    }
    this._getAdvertisementData("Beat")

  }

  gethooksListsApiCallFunction = (responseJson: any) =>{
    if (responseJson.data) {

      this.setState({ hooksList: responseJson.data, loading: false })
    } else if (responseJson.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
    this._getAdvertisementData("Hook")
  }

  getversesListsApiCallFunction = (responseJson: any) =>{
   
    if (responseJson.data) {
      this.setState({ versesList: responseJson.data, loading: false })
    } else if (responseJson.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
    this._getAdvertisementData("Verse")
  }

  getplayListsApiCallFunction = (responseJson: any) =>{
   
    if (responseJson.data) {
      this.setState({ playList: responseJson.data, loading: false })
    } else if (responseJson.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
    this._getAdvertisementData("Playlist")
  }

  handleAdList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
    const { active } = this.state
    let CurrentActiveTab="beatsList"
    if(active==0){
      CurrentActiveTab= "beatsList"
    } else if(active==1){
      CurrentActiveTab= "hooksList"
    }else if(active==2){
      CurrentActiveTab= "versesList"
    }else if(active==3){
      CurrentActiveTab= "songsList"
    }else{
      CurrentActiveTab="playList"
    }
    if (active !== 5) {
        this.setState((prevState) => ({
            [CurrentActiveTab]: [...responseJson?.data, ...(prevState as any)[CurrentActiveTab]], 
            loading:false
        } as any));

    } else {
        this.setState(prevState => ({
            playList:
                [...responseJson?.data, ...prevState.playList],
                loading:false
        }))

    }


}
  }

  handleFilterApiCallFunctionIf = (list:any, responseJson: any ) =>{
    if (list === 'beatsList') {
      this.setState({ beatsList: responseJson.data, loading: false })
    } else if (list === 'hooksList') {
      this.setState({ hooksList: responseJson.data, loading: false })
    } else if (list === 'versesList') {
      this.setState({ versesList: responseJson.data, loading: false })
    } else if (list === 'songsList') {
      this.setState({ songsList: responseJson.data, loading: false })
    } else {
      this.setState({ playList: responseJson.data, loading: false })
    }
  }

  handleFilterApiCallFunctionMessageElseIf = (list: any,responseJson: any) =>{
    if (list === 'beatsList') {
      this.setState({ beatsList: [], loading: false })
      openNotification(responseJson?.message, "Success")
      
    } else if (list === 'hooksList') {
      this.setState({ hooksList: [], loading: false })
      openNotification(responseJson?.message, "Success")

    } else if (list === 'versesList') {
      this.setState({ versesList: [], loading: false })
      openNotification(responseJson?.message, "Success")

    } else if (list === 'songsList') {
      this.setState({ songsList: [], loading: false })
      openNotification(responseJson?.message, "Success")

    } else {
      this.setState({ playList: [], loading: false })
      openNotification(responseJson?.message, "Success")

    }
  }

  filterFunction = () =>{
    if(window.location.href.includes("/hooks")){
      return "hooksList"
    }else if(window.location.href.includes("/verses")){
      return "versesList"
    }else if(window.location.href.includes("/songs")){
      return "songsList"
    }else{
      return "playList"
    } 
  }

  filterApiSubFunction = (list: any, responseJson: any) =>{
    if (responseJson.data) {
      this.handleFilterApiCallFunctionIf(list,responseJson)
    } else if (responseJson.message) {
      this.handleFilterApiCallFunctionMessageElseIf(list,responseJson)            
    }
    else if (responseJson.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

   apiResuestReceiveCallFunction = (apiRequestCallId: any, responseJson: any) =>{
    if (apiRequestCallId === this.getTop100ListsApiCallId) {
      this.getTop100ListsApiCallFunction(responseJson)
    }


    if (apiRequestCallId === this.getSongsListsApiCallId && responseJson !== undefined) {
      this.getSongsListsApiCallFunction(responseJson)
    }

    if (apiRequestCallId === this.beatsListsApiCallId && responseJson !== undefined) {
      this.beatsListsApiCallFunction(responseJson)
    }


    if (apiRequestCallId === this.gethooksListsApiCallId && responseJson !== undefined) {
      this.gethooksListsApiCallFunction(responseJson)
    }

    if (apiRequestCallId === this.getversesListsApiCallId && responseJson !== undefined) {
       this.getversesListsApiCallFunction(responseJson)
    }

    if (apiRequestCallId === this.getplayListsApiCallId && responseJson !== undefined) {
      this.getplayListsApiCallFunction(responseJson)
    }
 this.callReceiveFunction(apiRequestCallId,responseJson)

   }

   callReceiveFunction = (apiRequestCallId: any, responseJson: any) =>{
    if (apiRequestCallId === this.handleFilterApiCallId && responseJson !== undefined) {
      let list = window.location.href.includes("/beats") ? "beatsList" : this.filterFunction()
      this.filterApiSubFunction(list,responseJson)
    }
    if (apiRequestCallId === this.getAdList) {
      this.handleAdList(responseJson)
    }
   }
   

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        this.apiResuestReceiveCallFunction(apiRequestCallId,responseJson)
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

  }  

  handleSlug=(slug:any)=>{
    if (slug === "hooks") {
      this.setState({ active: 1, songIndex: 0 })
      this.gethooksLists()
    } else if (slug === "verses") {
      this.setState({ active: 2, songIndex: 0 })
      this.getversesLists()
    } else if (slug === "songs") {
      this.setState({ active: 3, songIndex: 0 })
      this.getSongsLists()
    } else if (slug == "videos") {
      this.setState({ active: 4, songIndex: 0 })
      this.getTop100Lists()
    } else if (slug === "playlists") {
      this.setState({ active: 5, songIndex: 0 })
      this.getplayLists()
    } else {
      this.setState({ active: 0, songIndex: 0 })
      this.getbeatsLists()
    }
  }
  async componentDidMount() {
    super.componentDidMount();
    let urlArr = window.location.href.split("/");
    let slug = urlArr.length !== 0 ? urlArr[5] : "";
    if (slug.includes('videos?id=')){
      slug = 'videos'
    }
    this.handleSlug(slug)
  }



  handleClickOpen = () => {
    const token: any = localStorage.getItem("token")
    const userType: any = localStorage.getItem("user_type")
    let dialogTextValue;

    if (token && !userType?.includes("listener")) {
      dialogTextValue = "1";
    } else if (userType?.includes("listener") && token) {
      dialogTextValue = "3";
    } else {
      dialogTextValue = "2";
    }
    this.setState({
      open: true,
      dialogText: dialogTextValue
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  }



  handleRedirectPlaylist = (id: any) => {
    const user_id: any = localStorage.getItem("user_id");
    const token: any = localStorage.getItem("token")
    const userType: any = localStorage.getItem("user_type")
    const subscriptionPlan: any = localStorage.getItem("subscription_plans")
    if (token && !userType.includes("listener") && id?.attributes?.artist_id == user_id || userType.includes("listener") && token && subscriptionPlan === "Listener+" && id?.attributes?.artist_id == user_id) {
      history.push(`/home/self-view/${id.id}`)
        localStorage.setItem("playlist_type", "Private")
    } else if (token && !userType.includes("listener") && id?.attributes?.artist_id !== user_id || userType.includes("listener") && token && subscriptionPlan === "Listener+" && id?.attributes?.artist_id !== user_id) {
      history.push(`/home/playlist/public-view/${id.id}`)
        localStorage.setItem("playlist_type", "Public")
    } else if (!token) {
      this.setState({ open: true, dialogText: "2" })
    } else {
      this.setState({ isPlaylist: false, alertPopup: true })
      this.handleClickOpen()
    }

  }



  callback = (event: any, newValue: number) => {
    localStorage.removeItem("selectedTab")
    if (newValue === 0) {
      history.push(`/home/Charts/beats`)
      this.setState({ filterValue: 'Filter' })
      this.getbeatsLists()
      this.setState({ songIndex: 0 })
    } else if (newValue === 1) {
      history.push(`/home/Charts/hooks`)
      this.setState({ filterValue: 'Filter' })
      this.gethooksLists()
      this.setState({ songIndex: 0 })
    } else if (newValue === 2) {
      history.push(`/home/Charts/verses`)
      this.setState({ filterValue: 'Filter' })
      this.getversesLists()
      this.setState({ songIndex: 0 })
    } else if (newValue === 3) {
      history.push(`/home/Charts/songs`)
      this.setState({ filterValue: 'Filter' })
      this.getSongsLists()
      this.setState({ songIndex: 0 })
    } else if (newValue === 4) {
      let token= localStorage.getItem('token')
      if(token){
        history.push(`/home/Charts/videos`)
        this.setState({ filterValue: 'Filter' })
        this.getTop100Lists()
      }else{
        history.push(`/home/Charts/playlists`)
        this.setState({ filterValue: 'Filter' })
        this.getplayLists()
        this.setState({ songIndex: 0 })
      }
    } else if (newValue === 5) {
      history.push(`/home/Charts/playlists`)
      this.setState({ filterValue: 'Filter' })
      this.getplayLists()
      this.setState({ songIndex: 0 })
    }

    let urlArr = window.location.href.split("/");
    let slug = urlArr.length !== 0 ? urlArr[5] : "";
    let token: any = localStorage.getItem("token")
    if(slug == "playlists" && !token){
      this.setState({ active: 4 })
    }else{
      this.setState({ active: newValue })
    }
  }



  getTop100Lists = async () => {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getTop100ListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetTop100Lists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetTop100Lists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  getSongsLists = async () => {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getSongsListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetSongsLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetSongsLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  getbeatsLists = async () => {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.beatsListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetBeatsLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetBeatsLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  gethooksLists = async () => {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.gethooksListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetHooksLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetHooksLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  getversesLists = async () => {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getversesListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetVersesLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetVersesLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  }


   handleLoginFilter = (value: any) => {
   let token= localStorage.getItem('token')
    if (!token) {
      history.push('/Welcome')
      window.location.reload()
    } else {
      this.handleFilter(value,window)

    }
  }
 
   setDialogText = (value: any) => {
    this.setState({ dialogText: value })
  }

  setHandleAudioVideo=(value: any) =>{

    this.setState({ handleAudioVideo: value })
  } 

  setHandlePlay=(value: any) =>{
    this.setState({ handleplay: value })

  } 

   setOpen = (value: any) => {
    this.setState({ open: value })
  }


  getplayLists = async () => {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getplayListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetplayLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetplayLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }


  handleFilter = async (value: any,window:any) => {
    const urlArr = window.location.href.split("/");
  const slug = urlArr.length !== 0 ? urlArr[5] : "";

  let CurrentValue;
  if (value === "today") {
    CurrentValue = "Today";
  } else if (value === "this_week") {
    CurrentValue = "This week";
  } else if (value === "this_month") {
    CurrentValue = "This month";
  } else {
    CurrentValue = "This Year";
  }

  let currentFilter;
  switch (slug) {
    case "beats":
      currentFilter = "Beat";
      break;
    case "hooks":
      currentFilter = "Hook";
      break;
    case "songs":
      currentFilter = "Song";
      break;
    case "verses":
      currentFilter = "Verse";
      break;
    case "videos":
      currentFilter = "Video";
      break;
    default:
      currentFilter = "Playlist";
  }

  this.setState({ filterValue: CurrentValue });
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.handleFilterApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointHandleFilter}?upload_type=${currentFilter}&filter_value=${value}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodHandleFilter
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  }



}
// Customizable Area End