// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import React from "react"
import { HISTORY } from "../../../../components/src/common";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("./config.js");
export interface Props {
    navigation: any;
    id: string;

}
interface S {
    dashboardData: any;
    token: string;
    errorMsg: string;
    loading: boolean;
    banner: any;
    creativeList: any;
    hooksList: any;
    songsList: any;
    beatsList: any;
    apiToken: any;
    originalList: any,
    playList: any,
    versesList: any,
    playlistData: any,
    checkType: any,
    selectedPlaylist: any,
    open: boolean,
    userType: any,
    dialogText: any,
    user_id: any,
    selected: any,
    handleAudioVideo: any;
    playPlaylist: boolean;
    bannerData: any;
    window_width: any;
    originalId: any;
    bannerOption: any;
    bannerTrack: any;
    currentTabType: string;
}
interface SS {
    id: any;
}


interface AdActitvityResponse{
    error:string;
    message:string
  }

  interface AdResponse{
    data:[{
        "id": string,
        "type": string,
        "attributes": {
            "id": number | string,
            "account_id": number,
            "track_id": number,
            "title": string,
            "track_type": string,
            "track_file": string,
            "video_file": null,
            "art_work": string,
            "push_content_type": string,
            "status": string
    }}],
    message:string;
    errors:string
  }
export default class MainContentController extends BlockComponent<Props, S, SS> {
    apiDashboardItemCallId: string = "";
    dashboardApiCallId: string = "";
    apiGetQueryStrinurl: string = "";
    orginalRef: any;
    topBeatRef: any;
    topHooksRef: any;
    topPlaylistRef: any;
    topSongsRef: any;
    topVersesRef: any;
    gethandlepreviewlist: any;
    getsongslists: any;
    getbeatlists: any;
    gethooklists: any;
    getverselists: any;
    getplaylists: any;
    getoriginallists: any;
    getalloriginallists: any;
    getcreativedataList: any;
    getAllBannerList: any;
    getAdvertisementList: any;
    apigetAdActivityCallId:any
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.orginalRef = React.createRef();
        this.topBeatRef = React.createRef();
        this.topHooksRef = React.createRef();
        this.topPlaylistRef = React.createRef();
        this.topSongsRef = React.createRef();
        this.topVersesRef = React.createRef();

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            dashboardData: [],
            errorMsg: "",
            token: "",
            loading: false,
            banner: [],
            creativeList: [],
            hooksList: [],
            songsList: [],
            beatsList: [],
            originalList: [],
            playList: [],
            versesList: [],
            playlistData: [],
            checkType: [],
            selectedPlaylist: [],
            open: false,
            apiToken: localStorage.getItem("token"),
            userType: localStorage.getItem("user_type"),
            user_id: localStorage.getItem("loggedIn_userId"),
            dialogText: "",
            selected: null,
            handleAudioVideo: 0,
            playPlaylist: false,
            bannerData: [],
            window_width: 0,
            originalId: 0,
            bannerOption: "",
            bannerTrack: "",
            currentTabType: "Beat"

        };
        this.windowResized = this.windowResized.bind(this);
        this.updateLandingWindowWidth = this.updateLandingWindowWidth.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        window.addEventListener("resize", this.windowResized);
        this.updateLandingWindowWidth();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener('willFocus', () => {
                this.getToken();
            });
        }
        localStorage.removeItem("selectedTab")
        this.setState({ loading: true })
        this.getBannerList();
        this.getplayLists()
        if (this.state.apiToken) {
            this.getCreativeLists(this.state.apiToken)
            this.getOriginalID();

        }

    }

    handleAllApiCall = async () => {

    }


    handleAdActivityClick=async(id:string,activity:string)=>{
        const header = {
          "Content-Type": "application/json",
          token: localStorage.getItem('token')
        };
        let paramsBody={
          id:id,
          status:activity
        }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.apigetAdActivityCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'bx_block_promotecontent/ad_campaigns/activity_in_ad_campaign'
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(paramsBody)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    

    updateLandingWindowWidth() {
        const windowLandingWidth = window.innerWidth;
        let columns;
        if (windowLandingWidth <= 1560 && windowLandingWidth >= 1393) {
            columns = 6;
        } else if (windowLandingWidth <= 1393 && windowLandingWidth >= 1088) {
            columns = 5;
        } else if (windowLandingWidth <= 1088 && windowLandingWidth >= 800) {
            columns = 4;
        } else if (windowLandingWidth <= 800 && windowLandingWidth >= 540) {
            columns = 3;
        } else if (windowLandingWidth <= 540 && windowLandingWidth >= 380) {
            columns = 2;
        } else if (windowLandingWidth <= 380) {
            columns = 1;
        }

        this.setState({
            window_width: columns,
        });
    }

    windowResized() {
        let _this = this;
        setTimeout(function () {
            _this.updateLandingWindowWidth();
        }, 500);
    }

    handleClickOpen = () => {
        if (this.state.apiToken && !this.state.userType?.includes("listener")) {
            this.setState({ dialogText: "1" });
        } else if (this.state.userType?.includes("listener") && this.state.apiToken) {
            this.setState({ dialogText: "3" });

        } else {
            HISTORY.push('/Welcome')
        }

    };

    redirectToLogin = () => {
        if (this.state.open) {
            HISTORY.push('/Welcome')
        }
    }

    setPlaylistData = (data: any) => {
        this.setState({ playlistData: data })
    }
    creativeOnClick = () => {
        this.state.apiToken ? (
            HISTORY.push("/home/Creatives")
        ) :

            this.setState({ open: true })
        this.setState({ dialogText: "2" })

    }
    handleClose = () => {
        this.setState({ open: false })
    };
    hadleRedirectPlaylist = async (id: any) => {
        if (this.state.apiToken) {
            if (this.state.apiToken && id?.attributes?.artist_id == this.state.user_id) {
                HISTORY.push(`/home/self-view/${id.id}`)
                localStorage.setItem("playlist_type", "Private")
            } else if (this.state.apiToken && id?.attributes?.artist_id !== this.state.user_id) {
                HISTORY.push(`/home/playlist/public-view/${id.id}`)

                localStorage.setItem("playlist_type", "Public")
            } else {
                this.setState({ open: true })
                this.setState({ dialogText: "2" })
            }
        } else {
            this.handleClickOpen();
        }

    }

    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }

    _checkAdvertisementData = async (current_list: string) => {
        this.setState({ currentTabType: current_list })
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAdvertisementList = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_promotecontent/ad_campaigns/get_player_ads?upload_type=${current_list.toLowerCase()}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }



    getBannerList = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllBannerList = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `/bx_block_audiomusic/banners`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }


    handleBannerPreview = async (options: any, id: any, track: any) => {
        this.setState({ bannerOption: options, bannerTrack: track })
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.gethandlepreviewlist = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.gethandlepreview}?id=${id}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getCreativeLists = async (token: any) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getcreativedataList = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getcreativedata}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    //song lists
    getSongsLists = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getsongslists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getalltablist}?upload_type=Song`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    //beats lists
    getbeatsLists = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getbeatlists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getalltablist}?upload_type=Beat`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }

    //hooks lists
    gethooksLists = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.gethooklists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getalltablist}?upload_type=Hook`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }
    //verses lists
    getversesLists = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getverselists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getalltablist}?upload_type=Verse`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    //play lists
    getplayLists = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getplaylists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getplaylistlist}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }

    //play lists
    getOriginalID = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getoriginallists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getoriginaldata}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    }


    getallOriginalLists = async (id: any) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getalloriginallists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getalloriginallist}?upload_type=Video&user_id=${id}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    redirectToOriginals = () => {
        localStorage.removeItem("selectedTab")
        const id: any = localStorage.getItem("user_id")
        localStorage.setItem("temp_id", id)
        localStorage.removeItem("user_id")
        localStorage.setItem("user_id", this.state.originalId)
        localStorage.setItem("creatives_screen", "true")
        const user = "original";
        localStorage.setItem("current_type", user);
        HISTORY.push(`/originals/videos?originals_id=${this.state.originalId}`,
        )

    }

    handlePreviewList = (responseJson: any) => {

        this.setState({ bannerData: responseJson?.data })
        if (this.state.bannerOption == "Watch") {
            HISTORY.push({
                pathname: "/home/Charts/videos",
                state: { video_list: this.state.bannerTrack, activeNo: "5", data: responseJson?.data, currentList: this.state.banner ? this.state.banner : [] }
            })
        } else {
            HISTORY.push({
                pathname: "/home/expand/song/songId=banner",
                state: {
                    data: responseJson?.data,

                }
            })
        }
    }

    handleCreativeList = (responseJson: any) => {
        this.setState({
            creativeList: responseJson?.data,
        });
      
    }

    handleGetSongsList = async (responseJson: any) => {
        this.setState({ songsList: responseJson?.data })
        await this._checkAdvertisementData("Song")
    }

    handleBeatList = async (responseJson: any) => {
        this.setState({ beatsList: responseJson?.data })
        await this._checkAdvertisementData("Beat")
    }

    handleHookList = async (responseJson: any) => {
        this.setState({ hooksList: responseJson?.data })
        await this._checkAdvertisementData("Hook")
    }

    handleVerseList = async (responseJson: any) => {
        this.setState({ versesList: responseJson?.data })
        await this._checkAdvertisementData("Verse")
    }

    handleGetPlaylist = async (responseJson: any) => {
        this.setState({ playList: responseJson?.data, loading: false })
        await this._checkAdvertisementData("Playlist")
    }

    transformData = (data: any[]) => {
        return data.map((item) => ({
            ...item,
            id: item.attributes.id
        }));
    };

    updateStateWithData = ( responseJson: AdResponse,currentTabType: string) => {
        const StateKeyToUpdate = `${currentTabType}sList`
        const toLowerCaseStateKey = StateKeyToUpdate.charAt(0).toLowerCase() + StateKeyToUpdate.slice(1)
        const transformedData = this.transformData(responseJson.data);
        this.setState((prevState) => {
            const currentData = (prevState as any)[toLowerCaseStateKey];
            
            const sortedData = [
                ...transformedData,
                ...currentData.sort((a: any, b: any) => b.attributes.play_count - a.attributes.play_count)
            ];
            return {
                [toLowerCaseStateKey]: sortedData,
            } as any;
        });
    }


    fetchAdditionalLists = (tabType: string) => {
        switch (tabType) {
            case "Song":
                this.getbeatsLists();
                break;
            case "Beat":
                this.gethooksLists();
                break;
            case "Hook":
                this.getversesLists();
                break;
            case "Playlist":
                this.getSongsLists();
                break;
            default:
                break;
        }
    };
    
    handleShowAdvertisement = (responseJson: any) => {
        const { currentTabType } = this.state
        if (responseJson !== undefined && !responseJson.message && !responseJson.error) {
        if (currentTabType !== "Playlist") {
            this.updateStateWithData(responseJson,currentTabType)
            this.fetchAdditionalLists(currentTabType)

        } else {
           
            this.setState((prevState) => {
                const sortedData = [
                    ...responseJson?.data, 
                    ...prevState.playList.sort((a: any, b: any) => b.attributes.play_count - a.attributes.play_count)
                ];

                return {
                    playList: sortedData,
                }
            })
           this.getSongsLists()

        }
        responseJson?.data.forEach((item:any)=>{
            this.handleAdActivityClick(item.id,"impression")
        })
    }else{
        this.fetchAdditionalLists(currentTabType)
    }
    }

        handleOriginalList = (responseJson: any) => {
            this.setState({ originalId: responseJson?.data?.id })
            this.getallOriginalLists(responseJson?.data?.id)
        }

        handleAllOriginalList = (responseJson: any) => {
            if (responseJson !== undefined && !responseJson.errors && !responseJson.message) {
                this.setState({
                    originalList: responseJson?.data,
                });
            }
        }

        handleAllBannerList = (responseJson: any) => {
            this.setState({
                banner: responseJson?.data,
            })
        }
        handleSeeAll = () => {
            this.setState({ open: true, dialogText: "2" });
        }

        handleAudioVideoValue = (value: any) => {
            this.setState({
                handleAudioVideo: value,
            })
        }

        handleSetSelected = (key: any) => {
            this.setState({ selected: key });
        }

        handleAdActivityCallResponse=(responseJson:AdActitvityResponse)=>{
            if (responseJson !== undefined && !responseJson.error) {
              return
          }else{
              openNotification(responseJson.message,"Error")
          }
          }

    async receive(from: string, message: Message) {
            runEngine.debugLog('Message Received', message)
            if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
                return;
            }

            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            if (apiRequestCallId == null) {
                this.parseApiCatchErrorResponse(errorReponse);
                return;
            }
            if (responseJson !== undefined && !responseJson.errors) {
                switch (apiRequestCallId) {
                    case this.getsongslists:
                        this.handleGetSongsList(responseJson)
                        break;
                    case this.getAdvertisementList:
                        this.handleShowAdvertisement(responseJson)
                        break;
                    case this.gethooklists:
                        this.handleHookList(responseJson)
                        break;
                    case this.getverselists:
                        this.handleVerseList(responseJson)
                        break;
                    case this.getplaylists:
                        this.handleGetPlaylist(responseJson)
                        break;
                    case this.getbeatlists:
                        this.handleBeatList(responseJson)
                        break;
                    case this.gethandlepreviewlist:
                        this.handlePreviewList(responseJson)
                        break;
                    case this.getcreativedataList:
                        this.handleCreativeList(responseJson)
                        break;

                    case this.getoriginallists:
                        this.handleOriginalList(responseJson)
                        break;
                    case this.getalloriginallists:
                        this.handleAllOriginalList(responseJson)
                        break;
                    case this.getAllBannerList:
                        this.handleAllBannerList(responseJson)
                        break;
                    case this.apigetAdActivityCallId:
                        this.handleAdActivityCallResponse(responseJson)
                        break;
                    default:
                        break;
                }
                this.setState({ loading: false })

            } else {
                this.setState({ loading: false })
            }
        }

    }
// Customizable Area End