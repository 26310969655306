// Customizable Area Start
import React,{useEffect, useState} from "react";
import "blocks/user-profile-basic/src/Common/TopSongs.web.css";
import { Grid, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
    defaultProfile,
    iconUp,
    PlayIcon,
    repeatIcon,
    messageIcon,
    Sort_Up_large,

} from "blocks/dashboard/src/assets";
import UnlockContentCommon from "../../../../blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommon.web";
import { unlockRedirectionCheck } from '../../../../blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommonController.web';

const TopSongs = ({ songsList, setTopVideoObj, setSetCurrentKey, setRepostCount, trackId, getFilterTrack,
    setTrackId, setUserTrack, setPlayVideoFromRedirection,setSelectedVideo, setShareVideo, setSplitsTabIndex }: any) => {

    const [topVideos, setTopVideos] = useState<any>([])

    const setTopSongsData = (item: any, index: any) => {

        if(item.type == "banner"){
          setPlayVideoFromRedirection(true)
          setTopVideoObj(item.attributes)
          setSelectedVideo(item)
          setTrackId(item.attributes.video_file_id)
          setUserTrack(item.attributes.user_tracks?.data)
          setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1)
          setRepostCount(item.attributes.repost_count)
          setShareVideo(item)
          getFilterTrack(item?.attributes.video_file_id)
          setSplitsTabIndex({},0)
        }
        else{
          setPlayVideoFromRedirection(true)
          setTopVideoObj(item.attributes)
          setSelectedVideo(item)
          setTrackId(item.id)
          setUserTrack(item.attributes.user_tracks?.data)
          setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1)
          setRepostCount(item.attributes.repost_count)
          setShareVideo(item)
          getFilterTrack(item?.id)
          setSplitsTabIndex({},0)
        }
        localStorage.setItem("selectedSingleVideo", JSON.stringify({ ...item, currentKey: index < 9 ? "0" + Number(index + 1) : index + 1 }));
    }

    useEffect(() =>{
      if(songsList[0].type == "banner"){
        setTopVideos(songsList.filter((item: any) => item.attributes.banner_button == "Watch"))
      }
      else{
        setTopVideos(songsList)
      }
     
    },[songsList])

    function capitalizeFirstLetter(text: any) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

  return (
    <Grid container xs={12} className="baseline height100">
      <Grid container item xs={12}>
      {topVideos.length > 1 &&
        <Box className="top-head-Font mB8">Top {topVideos.length}</Box>
      }
      {topVideos.length <= 1 &&
        <Box className="top-head-Font mB8">Top 0</Box>
      }
      </Grid>
      <Grid container item xs={12} className="overflow-hidden-auto">
        {topVideos.length > 1 &&
          topVideos.map((item: any, index: any) => {
              return (

                 <Grid
                   item  
                    xs={12}    
                   className={`${item.id == trackId && 'parent-videos-list-highlight'} parent-videos-list mr5 cursor-pointer`}
                  onClick={() => {
                    unlockRedirectionCheck(setTopSongsData,[item, index],item)
                   }}
                   style={{display: 'flex', alignItems: 'center', justifyContent: 'left', padding: '0px 10px'}}
                 >
                    <Box className="top-video-count-grid margin-right-top-songs">
                      <span className="count_songs_font" style={{alignSelf: 'center'}}>
                        {Number(index + 1)}
                      </span>
                      <img src={Sort_Up_large} className="top-video-large-icon" />
                    </Box>
                    <Box 
                      className=" " style={{width: '100%'}}
                      >
                      <Box  className="topsongs-div" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                         <Box className="margin-right-top-songs" style={{position: 'relative'}}>
                         <UnlockContentCommon trackData={item} height="100%" width= '100%' padding="5px" />
                           <img
                             className={`top-video-images videos-image-height-width ${(item?.attributes?.art_work ||
                              item?.attributes?.image)? '' : 'no-art-cover'}`}
                            
                            style={{}}
                            src={
                              item?.attributes?.art_work ||
                              item?.attributes?.image ||
                             defaultProfile
                            }
                          />
                         </Box>
                      <Grid
                       container
                       className="ml10 txtAndBtnsWrapper_in_topSongsTab"
                       >

                      <Grid item xs={12}>
                        <Typography className="song-name-font">
                           {capitalizeFirstLetter(item?.attributes?.title) || "Unknown"}
                         </Typography>
                         <Typography className="topsongs_creative_font">
                           {capitalizeFirstLetter(item?.attributes?.artist_name) || "Unknown"}
                         </Typography>
                       </Grid>
                      <Grid item xs={12} className="top-video-combined-icon" style={{marginLeft: '-7px'}}>
                         <Grid item xs={4} className="top-video-align">
                          <img src={PlayIcon} height="24px" width="24px" />
                           <span className="play-songs-times">
                             {item?.attributes?.play_count ||
                            item?.attributes?.play_count === 0
                              ? item?.attributes?.play_count
                              : "22k"}
                          </span>
                        </Grid>
                        <Grid item xs={4} className="top-video-align">
                          <img src={repeatIcon} height="24px" width="24px" />
                          <span className="play-songs-times">
                            {item?.attributes?.repost_count ||
                            item?.attributes?.repost_count === 0
                              ? item?.attributes?.repost_count
                              : "22k"}
                          </span>
                        </Grid>
                        <Grid item xs={4} className="top-video-align ">
                          <img src={messageIcon} height="20px" width="20px" />
                          <span className="play-songs-times ml4">
                             {item?.attributes?.comments_count ||
                            item?.attributes?.comments_count === 0
                              ? item?.attributes?.comments_count
                              : "22k"}
                          </span>
                        </Grid>
                        </Grid>
                         </Grid>
                         </Box>
                    </Box>
                  </Grid>


              );
          })}

          {topVideos.length <= 1 &&
          <div style={{color: 'white',alignItems: 'center', width: '100%', display: 'flex',justifyContent: 'center',fontSize: '16px',fontWeight: 'bold',marginTop: '50px'}}>
            No Top Songs
          </div>
          }
      </Grid>
    </Grid>
  );
};
export default TopSongs;
// Customizable Area End
