// Customizable Area Start
import React from "react";
import ChartVideosController from './ChartVideosController'
import Comments from '../../../../../components/src/CustomChart/Comments.web'

export default class Videos extends ChartVideosController {
  render() {
    return (
      <div>
        <Comments
          //@ts-ignore
          songsList={this.state.songsList}
          toggleComment={this.state.toggleComment}
          commentTabs={this.state.commentTabs}
          handleToggle={this.handleToggle}
          handleCommentTabs={(data: any) =>
            this.handleCommentTabs(data)
          }
        />
      </div>
    );
  }
}

// Customizable Area End
